<template>
	<div :class="chatMessageClasses">
		<PageChatOrder
			v-if="message.type === 'event'"
			:order="message" />

		<div
			v-else-if="message.type === 'file'"
			class="chat-message__wrapper">
			<PageChatMessageFile
				class="chat-message__text"
				:message="message as IMessageFile" />
		</div>

		<div
			v-else
			class="chat-message__wrapper">
			<div class="chat-message__content">
				<span
					v-if="message.type === 'system' && message.call_status !== ''"
					class="chat-message__text chat-message__status">
					Статус: {{ message.call_status }}
				</span>

				<span v-if="message.messages" class="chat-message__text">
					{{ highlight(message.messages, message.created) }}
				</span>
			</div>

			<PageChatChatMessageMeta :message="message" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { type IMessage } from "@/assets/ts/global/interfaces/IMessage";
	import type { IMessageFile } from "@/assets/ts/global/interfaces/IMessageFile";
	import { useDateFormat } from "@/composables/useDateFormat";

	const props = defineProps<{ message: IMessage | IMessageFile }>();

	const chatMessageClasses = computed(() => [
		"chat-message",
		{
			"chat-message--left": props.message.type === "inc" || props.message.type === "event",
			"chat-message--system chat-message--gray chat-message--centre": props.message.type === "system",
			"chat-message--white": props.message.type === "out" || props.message.type === "file",
		},
	]);

	const highlight = (content: string, date: string): string => content.replaceAll("{{created}}", useDateFormat(date, "YYYY-MM-DD HH:mm:ss"));
</script>

<style lang="scss">
	@import "chat-message";
</style>
